import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosure from "@tightrope/ctadisclosure"
import RedbrickBM from "@tightrope/redbmimic"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import NonSearchDisclosure from "src/components/nonsearchdisclosure"

const prdbestData = import("./data/data.json");
const mimrbData = import("./data/mimrbData.json");

const prdbestBrowsers = ['other','ie','firefox','chrome','edge'];

let css;
  if(isChrome()){
    css = `  .trbm-module--trbm .trbm-module--bullets {
        padding: 0 0 0 85px;
        width: 75%;
      }
      .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }`;
  }
  else{
    css = `  .trbm-module--trbm .trbm-module--bullets {
        padding: 0 0 0 85px;
        width: 75%;
      }
      .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: auto !important;
        transition: none !important;
    }
    button.trbm_cta:hover {
      transform: none !important;
  }
    `;
  }


export default function prdbest() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <CtaDisclosure data={prdbestData}></CtaDisclosure>;
  }
  else{
    disclosure = <CtaDisclosure data={prdbestData}></CtaDisclosure>;
  }
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/laptop-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }


    .footerlinks-module--br, .footerlinks-module--br a {
      color: #f1f1f1;
      font-size: 13px;
    }

    .redbmimic-module--rboverlay {
      background-color: #666;
    }

    .redbmimic-module--edBtn {
      background: #F5883D!important;
      font-weight: bold;
    }

    .redbmimic-module--rboverlay h1 {
      font-size: 34px;
      text-align: center;
      color: #000;
      margin: 0px 0px 50px;
    }

    .redbmimic-module--imgdiv {
      border: 1px solid #f1f1f1;
    }

    #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure, .nonsearch-module--disclosure {
      color: #666;
      width: 600px;
      margin: 0 auto;
    }
    a.ctadisclosure-module--bold {
      font-weight: 400 !important;
    }
    `}
    </style>

    <style type="text/css">
      {css}
    </style>

    <title>Free PDF Editor - pdftab.com</title></Helmet>
    <section>
      <Trbm data={prdbestData} browsers={prdbestBrowsers}>
        {disclosure}
      </Trbm>
      </section>
    </HomepageLayout>
  )
}
